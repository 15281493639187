import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

type ToggleButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

const ToggleButton: FC<ToggleButtonProps> = ({ onClick, type }) => {
  return (
    <button
      className="toggle-button"
      onClick={onClick}
      type={type}
      tabIndex={-1}
      aria-label="toggle-button"
      onMouseDown={(e) => {
        // Prevent input from losing focus.
        e.preventDefault();
      }}>
      <FontAwesomeIcon icon={faChevronDown} />
    </button>
  );
};

export default ToggleButton;
