import { FC, forwardRef, MutableRefObject } from 'react';
import { Typeahead as TypeaheadComponent } from 'react-bootstrap-typeahead';
import useTypeaheadDropup, { ZimTypeaheadProps } from '../../hooks/useTypeaheadDropup';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

type ZimTypeahead = ReturnType<typeof TypeaheadComponent>;

const ZimTypeahead: FC<ZimTypeaheadProps<typeof TypeaheadComponent>> = forwardRef(
  (props, ref: MutableRefObject<Typeahead>) => {
    const { dropup, typeaheadRef, newProps } = useTypeaheadDropup<typeof TypeaheadComponent>(
      props,
      ref
    );

    return <TypeaheadComponent ref={typeaheadRef} {...newProps} dropup={dropup} />;
  }
);

export default ZimTypeahead;
