import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import {
  useRelativeHeightPosition,
  UseRelativeHeightPositionData
} from './useRelativeHeightPosition';
import { Typeahead as TypeaheadComponent, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { InputProps, TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types';
import { useImperativeHandle } from 'react';
import { type } from 'os';

type Typeaheads = typeof TypeaheadComponent | typeof AsyncTypeahead;

export type ZimTypeaheadProps<T extends Typeaheads> = Omit<Parameters<T>[0], 'dropup'>;

const useTypeaheadDropup = <T extends Typeaheads>(
  props: ZimTypeaheadProps<T>,
  ref: MutableRefObject<Typeahead>
) => {
  const fallbackRef = useRef<Typeahead>(null);
  const inputRef = useRef<HTMLElement>();

  // in case original ref was not created, we want to reate it inside the hook
  const typeaheadRef = useMemo(() => ref ?? fallbackRef, [ref]);

  const [dropup, setDropUp] = useState(false);

  // Since input ref is hidden behind Typeahead and API of a library allows us to get ref to actual component, instead of input,
  // we have to instantiate inputRef by ourselfs after component has been rendered
  useLayoutEffect(() => {
    inputRef.current = typeaheadRef?.current?.inputNode;
  }, []);

  const repositionDropup = useCallback(({ isBottomDirection }: UseRelativeHeightPositionData) => {
    setDropUp(!isBottomDirection);
  }, []);

  useRelativeHeightPosition(inputRef, repositionDropup);

  const inputProps = useMemo<InputProps>(() => {
    const newOnClick: typeof props.inputProps.onClick = (event) => {
      props?.inputProps?.onClick && props.inputProps.onClick(event);
      typeaheadRef.current?._handleClear();
      typeaheadRef.current?.toggleMenu();
    };
    return { ...props.inputProps, onClick: newOnClick };
  }, [props.inputProps]);

  const newProps = useMemo(() => {
    return { ...props, inputProps };
  }, [props, inputProps]);
  return { dropup, typeaheadRef, newProps };
};

export default useTypeaheadDropup;
