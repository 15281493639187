import { forwardRef, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead';
import { Ref, useForm } from 'react-hook-form';
import { env } from '../../../environments/environment';
import { getData } from '../../../services/crud';
import './styles/ScheduleLine.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ToggleButton from './ToogleButton';
import ZimTypeahead from '../../ZimTypeahead/ZimTypeahead';
import { schedulesHelper } from '../../../helpers/schedulesHelper';
import classNames from 'classnames';
import { stringExtensions } from '../../../helpers/stringExtensions';

export interface IOption {
  disabled: boolean;
  group: string | null;
  selected: boolean;
  text: string;
  value: string | null;
}

export type ScheduleLineSubmit = {
  trade: string;
  line: string;
  lineName: string;
  tradeName: string;
};

export type SubmitProps = {
  cssClass: string;
  value: string;
};

export type ScheduleLineRenderProps = {
  holderClass: string;
  typeaheadClass: string;
  showLabels: boolean | null;
  submit?: SubmitProps;
};

interface IScheduleLineProps {
  mode: string;
  render?: ScheduleLineRenderProps | null;
  submitHandler?: (data: ScheduleLineSubmit) => any;
  preValueHandler?: (data: ScheduleLineSubmit) => any;
  inputElement?: boolean;
  trade?: string;
  line?: string;
  mainSearch?: boolean;
  quickSearch?: boolean;
  quickActions?: boolean;
}

const ScheduleLine = forwardRef<HTMLFormElement, IScheduleLineProps>((props, ref) => {
  const { t } = useTranslation();
  const translations = useMemo(() => {
    return {
      lineTradeRequired: t('scheduleByLine.TradeRequired'),
      lineLineRequired: t('scheduleByLine.LineRequired'),
      lineSelectTrade: t('scheduleByLine.SelectTrade'),
      lineSelectLine: t('scheduleByLine.SelectLine'),
      search: t('menu.Search'),
      tradeLabel: t('scheduleByLineSearch.Trade'),
      lineLabel: t('scheduleByLineSearch.Line')
    };
  }, [t]);

  const [renderProps, setrenderProps] = useState<ScheduleLineRenderProps>(() => {
    return {
      holderClass: props.render?.holderClass ?? 'line-schedule-form',
      typeaheadClass: props.render?.typeaheadClass ?? 'col-md-4 input-wrapper',
      showLabels: false,
      submit: props.render?.submit ?? {
        cssClass: 'btn btn-primary col-lg-1',
        value: translations?.search
      }
    };
  });

  const tradeRef = useRef(null);
  const lineRef = useRef(null);

  const [tradeOptions, setTradeOptions] = useState<IOption[]>([]);
  const [lineOptions, setLineOptions] = useState<IOption[]>([]);
  const [selectedTrade, setSelectedTrade] = useState([]);
  const [selectedLine, setSelectedLine] = useState([]);
  const [qaSelectedLine, setQaSelectedLine] = useState<boolean>(false);

  const schema = yup
    .object({
      trade: yup.string().required(translations?.lineTradeRequired),
      line: yup.string().required(translations?.lineLineRequired)
    })
    .required();
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      trade: '',
      line: ''
    }
  });

  const formSubmit = (line: string, trade: string) => {
    if (props.submitHandler) {
      props.submitHandler({
        line: line,
        trade: trade,
        lineName: lineOptions.filter((item) => item.value === line)[0].text,
        tradeName: tradeOptions.filter((item) => item.value === trade)[0].text
      });
    } else {
      const params = `trade=${trade}&line=${line}`;
      schedulesHelper.submitRedirect(env.fixed.scheduleByLine.onSearch, params);
    }
  };

  const onSubmit = handleSubmit((data: any) => {
    formSubmit(data.line, data.trade);
  });

  useEffect(() => {
    getData(env.fixed.scheduleByLine.tradesGet).then(
      (data: IOption[]) => {
        setTradeOptions(data);
        if (props?.trade) {
          setSelectedTrade(data.filter((item) => item.value === props.trade));
          setValue('trade', props.trade);
          getData(`${env.fixed.scheduleByLine.linesGet}${props.trade}`).then(
            (lines: IOption[]) => {
              setLineOptions(lines);
              if (props?.line) {
                setSelectedLine(lines.filter((item) => item.value === props.line));
                setValue('line', props.line);
                props.preValueHandler({
                  line: props.line,
                  trade: props.trade,
                  lineName: lines.filter((item) => item.value === props.line)[0].text,
                  tradeName: data.filter((item) => item.value === props.trade)[0].text
                });
              }
            },
            (err) => console.log(err)
          );
        }
      },
      (err) => console.log(err)
    );
  }, [props.trade, props.line]);

  const onTradeOptionSelected = (data: IOption[]) => {
    if (data?.length) {
      setValue('trade', data[0].value);
      if (props?.mainSearch || props?.quickSearch) {
        setSelectedTrade(tradeOptions.filter((item) => item.value === data[0].value));
      }

      getData(`${env.fixed.scheduleByLine.linesGet}${data[0].value}`).then(
        (lines: IOption[]) => {
          setLineOptions(lines);
        },
        (err) => console.log(err)
      );
    } else {
      setValue('trade', '');
      setValue('line', '');
      setLineOptions([]);
      setQaSelectedLine(false);
      if (props?.mainSearch || props?.quickSearch) {
        setSelectedTrade([]);
        setSelectedLine([]);
      }
      if (props?.quickActions && lineRef && lineRef.current) {
        lineRef.current.clear();
      }
    }
  };

  const onLineOptionSelected = (data: IOption[]) => {
    if (data?.length) {
      setValue('line', data[0].value);
      setQaSelectedLine(true);
      if (props?.mainSearch || props?.quickSearch) {
        setSelectedLine(lineOptions.filter((item) => item.value === data[0].value));
        if (props?.mainSearch) {
          formSubmit(data[0].value, selectedTrade[0].value);
        }
      }
    } else {
      setQaSelectedLine(false);
      setValue('line', '');
      if (props?.mainSearch || props?.quickSearch) {
        setSelectedLine([]);
      }
    }
  };

  return (
    <form className={`line-search ${renderProps.holderClass}`} onSubmit={onSubmit} ref={ref}>
      <div
        className={classNames(renderProps.typeaheadClass, {
          'align-self-md-start':
            props.mode && props.mode === 'globalNetwork' && errors.line?.message
        })}>
        {props.render?.showLabels && (
          <label className="label hidden-print" htmlFor="Trade">
            {translations.tradeLabel}
            <span className="required">*</span>
          </label>
        )}
        <ZimTypeahead
          id={`${props.mode}-line-schedule-trade`}
          labelKey="text"
          options={tradeOptions}
          className={classNames('', {
            'item-selected':
              props.mode === 'globalNetwork' &&
              tradeOptions?.length > 0 &&
              !stringExtensions.isEmptyOrSpaces(getValues().trade)
          })}
          placeholder={translations?.lineSelectTrade}
          inputProps={{
            'aria-label': translations?.lineSelectTrade,
            id: `${props.mode}-line-schedule-trade`
          }}
          onChange={onTradeOptionSelected}
          selected={props?.mainSearch || props?.quickSearch ? selectedTrade : undefined}
          ref={tradeRef}>
          {({ toggleMenu, isMenuShown, onClear }) => (
            <>
              {props.inputElement && (
                <ToggleButton
                  type="button"
                  onClick={(e) => {
                    !isMenuShown && onClear();
                    toggleMenu();
                  }}
                />
              )}
            </>
          )}
        </ZimTypeahead>
        {errors.trade?.message && (
          <span role="alert" className="error-msg">
            {errors.trade?.message}
          </span>
        )}
      </div>
      <div
        className={classNames(renderProps.typeaheadClass, {
          'align-self-md-start':
            props.mode && props.mode === 'globalNetwork' && errors.trade?.message,
          'ms-20': props.mode === 'globalNetwork'
        })}>
        {props.render?.showLabels && (
          <label className="label hidden-print" htmlFor="Line">
            {translations.lineLabel}
            <span className="required">*</span>
          </label>
        )}
        <ZimTypeahead
          id={`${props.mode}-line-schedule-line`}
          labelKey="text"
          disabled={!lineOptions[0]?.value}
          options={lineOptions}
          className={classNames('', {
            'item-selected':
              props.mode === 'globalNetwork' && lineOptions?.length > 0 && qaSelectedLine
          })}
          placeholder={translations?.lineSelectLine}
          inputProps={{
            'aria-label': translations?.lineSelectLine,
            'aria-disabled': !lineOptions[0]?.value,
            id: `${props.mode}-line-schedule-line`
          }}
          onChange={onLineOptionSelected}
          selected={props?.mainSearch || props?.quickSearch ? selectedLine : undefined}
          ref={lineRef}>
          {({ toggleMenu, isMenuShown, onClear }) => (
            <>
              {props.inputElement && (
                <ToggleButton
                  type="button"
                  onClick={(e) => {
                    !isMenuShown && onClear();
                    toggleMenu();
                  }}
                />
              )}
            </>
          )}
        </ZimTypeahead>
        {errors.line?.message && (
          <span role="alert" className="error-msg">
            {errors.line?.message}
          </span>
        )}
      </div>
      {!props.mainSearch && (
        <input
          className={classNames(renderProps.submit.cssClass, {
            'align-self-md-center':
              props.mode &&
              props.mode === 'globalNetwork' &&
              (errors.line?.message || errors.trade?.message)
          })}
          type="submit"
          value={renderProps.submit.value}
        />
      )}
    </form>
  );
});

export default ScheduleLine;
