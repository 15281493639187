import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export type UseRelativeHeightPositionData = {
  isBottomDirection: boolean;
  bottomOffset: number;
  topOffset: number;
};

export type UseRelativeHeightPositionReactiveData = {
  [Key in keyof UseRelativeHeightPositionData]: MutableRefObject<
    UseRelativeHeightPositionData[Key]
  >;
};

type UseRelativeHeightPositionResult = UseRelativeHeightPositionReactiveData;

const useRelativeHeightPosition = (
  element: MutableRefObject<HTMLElement>,
  handlePositionChange?: (data: UseRelativeHeightPositionData) => void | Promise<void>
): UseRelativeHeightPositionResult => {
  const topOffset = useRef<number>(0);
  const bottomOffset = useRef<number>(0);
  const isBottomDirection = useRef<boolean>(false);

  const handleLayoutEffect = useCallback(() => {
    if (!handlePositionChange || !element.current) {
      return;
    }

    const btnRects = element.current.getBoundingClientRect();

    const screenBottom = window.scrollY + window.innerHeight;
    const screenTop = window.scrollY;

    topOffset.current = Math.abs(window.scrollY + btnRects.y - screenTop);
    bottomOffset.current = Math.abs(window.scrollY + btnRects.y + btnRects.height - screenBottom);

    isBottomDirection.current = topOffset.current + btnRects.height < bottomOffset.current;

    handlePositionChange({
      bottomOffset: bottomOffset.current,
      isBottomDirection: isBottomDirection.current,
      topOffset: topOffset.current
    });
  }, [handlePositionChange, element.current]);

  useLayoutEffect(() => {
    document.addEventListener('scroll', handleLayoutEffect);
    document.addEventListener('resize', handleLayoutEffect);

    handleLayoutEffect();

    return () => {
      document.removeEventListener('scroll', handleLayoutEffect);
      document.removeEventListener('resize', handleLayoutEffect);
    };
  }, [handlePositionChange]);

  return { isBottomDirection, topOffset, bottomOffset };
};

export { useRelativeHeightPosition };
